<template>
  <CCard>
    <CCardHeader class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <CIcon name="cil-grid"/> Listado de clientes
      </div>
      <div>
        <CButton color="primary" @click="openModal()">
          Nuevo
        </CButton>
      </div>
    </CCardHeader>
    <CCardBody>

      <!-- MODAL -->
      <CModal
        :title="titleModal"
        size="lg"
        :show.sync="flagModal"
      >

        <CForm novalidate>
          <CCardBody>

            <CInput
              :value.sync="client.name"
              :disabled="loadingModal"
              @keyup.enter="saveClient()"
              description="Por favor ingresa un nombre."
              label="Nombre"
              placeholder="Ingresa un nombre..."
              required
              was-validated
            />

            <CInput
              :value.sync="client.document"
              :disabled="loadingModal"
              @keydown="validateNumber"
              @keyup.enter="saveClient()"
              description="Por favor ingresa el dni/ruc."
              label="DNI/RUC"
              maxlength="11"
              placeholder="Ingresa el dni/ruc..."
            />

            <!-- <CInput
              :value.sync="client.email"
              :disabled="loadingModal"
              @keyup.enter="saveClient()"
              description="Por favor ingresa un email."
              label="Email"
              placeholder="Ingresa un email..."
              required
              was-validated
            /> -->

            <CInput
                :value.sync="client.phone"
                :disabled="loadingModal"
                @keydown="validateNumber"
                @keyup.enter="saveUser()"
                description="Por favor ingresa un teléfono."
                label="Teléfono"
                placeholder="Ingresa un teléfono..."
              />

            <CInput
              :value.sync="client.address"
              :disabled="loadingModal"
              @keyup.enter="saveClient()"
              description="Por favor ingresa una dirección."
              label="Dirección"
              placeholder="Ingresa una dirección..."
              required
              was-validated
            />

            <CInput
              :value.sync="client.description"
              :disabled="loadingModal"
              @keyup.enter="saveClient()"
              description="Por favor ingresa una descripción."
              label="Descripción"
              placeholder="Ingresa una descripción..."
              was-validated
            />

          </CCardBody>
        </CForm>

        <template #footer>

          <div v-if="!loadingModal">
            <CButton color="primary" @click="saveClient()" class="mr-1 mb-3"><CIcon name="cil-save"/> <span v-text="textButton"></span></CButton>
          </div>
          <div v-else>
            <CCol xl="3" lg="4" md="6">
              <CCardBody>
                <div class="sk-chase">
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                </div>
              </CCardBody>
            </CCol>
          </div>

        </template>

      </CModal>

      <!-- MODAL: LISTADO DE VENTAS POR CLIENTE -->
      <CModalSalesByClient
        :isVisible="flagModalClientBySales"
        :client="clientData"
        @close-modal-sales-by-client="closeModalSalesByClient"
        @get-clients="getClients"
      />

      <!-- LIST -->
      <div v-if="loading" class="text-center">
        
        <CSpinner color="primary" />
        <p>Cargando...</p>
      
      </div>
      <div v-else>

        <!-- FILTROS -->
        <CRow>
          <CCol md="3">
            <CInput type="text" label="DNI" v-model="filters.document" />
          </CCol>
          <CCol md="3">
            <CInput type="text" label="Nombre" v-model="filters.name" />
          </CCol>
          <CCol md="3">
            <CSelect
              :value.sync="filters.type"
              :options=types
              label="Tipo Venta"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol md="6" class="d-flex align-items-center">
            <CButton color="primary" @click="getClients" class="mr-2" style="width: auto;">
              <CIcon name="cil-share" /> Buscar
            </CButton>
            <CButton color="info" @click="cleanFilters" class="mr-2" style="width: auto;">
              <CIcon name="cil-share" /> Limpiar filtros
            </CButton>
            <CButton color="success" @click="downloadExcelClients" style="width: auto;">
              <CIcon name="cil-cloud-download" /> Generar Excel
            </CButton>
          </CCol>
        </CRow>
        <br />

        <CDataTable
          :items="clients"
          :fields="fields"
          :items-per-page="10"
          :hover="hover"
          :striped="striped"
          :border="border"
          :small="small"
          :fixed="fixed"
          :dark="dark"
          pagination
        >

          <template #index="{ index }">
            <td>{{ index + 1 }}</td>
          </template>

          <!-- BUTTON VIEW -->
          <template #buttonViewSales="{item}">
            <td>
              <CButton
                :name="item.id"
                size="sm"
                :key="item.id"
                color="twitter"
                @click="openModalSalesByClient(item)"
              >
                <CIcon size="sm" name="cil-share"/>
              </CButton>
            </td>
          </template>

          <!-- BUTTON EDIT -->
          <template #buttonEdit="{item}">
            <td>
              <template v-if="!loadingButtonEdit">
                <CCardBody>
                  <div class="sk-chase">
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                  </div>
                </CCardBody>
              </template>
              <template v-else>
                <CButton
                  :name="item.id"
                  size="sm"
                  :key="item.id"
                  color="facebook"
                  @click="editModal(item.id)"
                >
                  <CIcon size="sm" name="cil-comment-square"/>
                </CButton>
              </template>
            </td>
          </template>

          <!-- BUTTON DELETE -->
          <template #buttonDelete="{item}">
            <td>
              <CButton
                :name="item.id"
                size="sm"
                :key="item.id"
                color="youtube"
                @click="deleteClient(item.id, item.name)"
              >
                <CIcon size="sm" name="cil-ban"/>
              </CButton>
            </td>
          </template>

        </CDataTable>
        
      </div>

    </CCardBody>
  </CCard>
</template>

<script>

  import Swal from "sweetalert2"
  import * as XLSX from 'xlsx';
  import {list, save, show, destroy} from '../../../assets/js/methods/functions.js'
  import CModalSalesByClient from "./ModalSalesByClient.vue";

  export default {
    name: 'TableClients',
    components: {
      CModalSalesByClient,
    },
    props: {
      items: Array,
      fields: {
        type: Array,
        default () {
          return [
            { key: 'index', label: '#' },
            { key: 'document', label: 'DNI' },
            { key: 'name', label: 'Nombre' },
            { key: 'phone', label: 'Teléfono' },
            { key: 'address', label: 'Dirección' },
            { key: 'description', label: 'Descripción' },
            { key: 'buttonViewSales', label: 'Ver', _style:'min-width:20%;' },
            { key: 'buttonEdit', label: 'Editar', _style:'min-width:20%;' },
            { key: 'buttonDelete', label: 'Eliminar', _style:'min-width:20%;' },
          ]
        }
      },
      caption: {
        type: String,
        default: 'TableClients'
      },
      hover: Boolean,
      striped: Boolean,
      border: Boolean,
      small: Boolean,
      fixed: Boolean,
      dark: Boolean,
    },
    mounted() {
      this.getClients();
    },
    data () {
      return {
        prefix_list: "clients",
        prefix: "client",
        clients: [],
        clientData: null,
        loading: true,
        types: ['ambas', 'contado', 'credito'],
        client: {
          id          : "",
          document    : "",
          name        : "",
          phone       : "",
          address     : "",
          description : "",
        },
        filters: {
          document  : "",
          name      : "",
          type      : "",
        },

        // Modal
        titleModal: "Nuevo Cliente",
        textButton: "Guardar",
        flagModal: false,
        flagModalClientBySales: false,
        loadingModal: false,
        loadingButtonEdit: true,
      }
    },
    methods: {
      async getClients(){
        
        this.loading = true;

        try {
          
          const url = this.$store.state.url;
          
          const response = await list(url + this.prefix_list, this.filters);

          if (response.status === 200) {
            this.clients = response.data.data;
          }

        } catch (errors) {

          if (errors.length > 0) {
            Swal.fire("Alerta", errors[0], "warning");
          } else {
            Swal.fire("Alerta", "Ocurrió un error desconocido", "error");
          }

        } finally {
          
          this.loading = false;
        
        }

      },
      async saveClient(){

        this.loadingModal = true;

        try {

          const url = this.$store.state.url;
          const data = this.getSetData(this.client);
          const response = await save(url + this.prefix, data, this.client.id);

          if (response.status === 200) {
            
            this.getClients();
            
            Swal.fire("Alerta", response.data.message, "success");
            this.flagModal = false;

          }

        } catch (errors) {
          
          if (errors.length > 0) {
            Swal.fire("Alerta", errors[0], "warning");
          } else {
            Swal.fire("Alerta", "Ocurrió un error desconocido", "error");
          }

        } finally {

          this.loadingModal = false;

        }
      },
      async editModal(id){

        try {

          this.flagModal = true;
          this.loadingModal = true;

          const url = this.$store.state.url;
          const response = await show(url+ this.prefix +`/${id}`);

          if (response.status === 200) {

            let data = response?.data?.data;

            this.client.id          = data?.id;
            this.client.document    = data?.document;
            this.client.name        = data?.name;
            this.client.phone       = data?.phone;
            this.client.address     = data?.address;
            this.client.description = data?.description;
            this.titleModal         = "Modificar Cliente";
            this.textButton         = "Modificar";

          }

        } catch (errors) {

          if (errors.length > 0) {
            Swal.fire("Alerta", errors[0], "warning");
          } else {
            Swal.fire("Alerta", "Ocurrió un error desconocido", "error");
          }

        } finally {

          this.loadingModal = false;

        }

      },
      async openModalSalesByClient(item){
        this.clientData = item;
        this.flagModalClientBySales = true;
      },
      closeModalSalesByClient() {
        this.getClients();        
        this.flagModalClientBySales = false;
      },
      async deleteClient(id, name){

        let el = this;

        Swal.fire({
          title: "¿Está seguro?",
          html: `Se eliminará el cliente '${name}'.`,
          icon: "warning",
          confirmButtonText: "Sí, eliminar",
          closeOnConfirm: false,
          showCancelButton: true,
          cancelButtonText: "Cancelar"
        })
        .then(async function(result) {

          if(result.value) {

            try {

              const url = el.$store.state.url;
              const response = await destroy(url+el.prefix+`/${id}`);
              
              if (response.status === 200) {

                el.getClients();
                Swal.fire("Alerta", response.data.message, "success");
                
              }

            } catch (errors) {
              
              if (errors.length > 0) {
                Swal.fire("Alerta", errors[0], "warning");
              } else {
                Swal.fire("Alerta", "Ocurrió un error desconocido", "error");
              }

            }

          }

        });

      },
      downloadExcelClients() {

        let data = [];
        let clients = this.clients;
        
        clients.forEach(client => {
            data.push({
                'DNI'         : client.document,
                'Nombre'      : client.name,
                'Teléfono'    : client.phone,
                'Dirección'   : client.address,
                'Descripción' : client.description,
            });
        });

        // Convertir los datos a una hoja de trabajo de Excel
        const worksheet = XLSX.utils.json_to_sheet(data);

        // Obtener las cabeceras (letras de las columnas) y aplicar estilos
        const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
        for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
            if (!worksheet[cellAddress]) continue;

            worksheet[cellAddress].s = {
                fill: {
                    fgColor: { rgb: "FFFF00" } // Fondo amarillo (RGB hex)
                },
                font: {
                    bold: true,
                    color: { rgb: "000000" } // Texto negro
                },
                alignment: {
                    horizontal: "center"
                }
            };
        }

        // Crear un nuevo libro y agregar la hoja de trabajo
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Productos');

        // Generar el archivo de Excel
        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
            cellStyles: true
        });

        // Crear un blob y desencadenar la descarga
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'reporte_clientes.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

      },
      openModal(){
        this.cleanModal();
        this.flagModal = true;
      },
      cleanModal(){
        this.client.id          = "";
        this.client.document    = "";
        this.client.name        = "";
        this.client.phone       = "";
        this.client.address     = "";
        this.client.description = "";
        this.titleModal         = "Nuevo Cliente";
        this.textButton         = "Guardar";
      },
      getSetData(data){
          
        let formData = new FormData();
        
        formData.append('document', data.document);
        formData.append('name', data.name);
        formData.append('phone', data.phone);
        formData.append('address', data.address);
        formData.append('description', data.description);
        
        return formData;

      },
      validateNumber(event) {

        const key = event.key;

        // Permite solo números, un solo punto decimal, y teclas útiles como Retroceso, Suprimir, etc.
        if (!/^[0-9]$/.test(key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(key)) {
          event.preventDefault();
          return;
        }

        // Permitir borrar (Backspace, Delete) y escribir nuevamente en la parte entera
        if (['Backspace', 'Delete'].includes(key)) {
          return; // Permite borrar sin restricciones
        }

      },
      cleanFilters() {
        this.filters = {
          document  : "",
          name      : "",
          type      : "",
        };
      },
    }
  }

</script>

<style src="spinkit/spinkit.min.css"></style>
